


































import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component
export default class PropostaContador extends Vue {
  public countdown: number = 0;
  public timeEnd = moment();
  public timeCurrent = moment();

  get protocolo() {
    return this.$store.state.cotacao.protocolo;
  }

  public mounted() {
    this.timeEnd = moment.unix(this.protocolo).add(6, 'days');
    if (!this.esgotado) {
      this.countdown = (setInterval(
        this.updateCountdown,
        1000
      ) as unknown) as number;
    }
  }

  public updateCountdown() {
    this.timeCurrent = moment();
    if (this.esgotado) {
      clearInterval(this.countdown);
    }
  }

  public get diff() {
    return this.timeEnd.diff(this.timeCurrent);
  }

  public get remaining() {
    return moment(this.diff);
  }

  public get esgotado() {
    return this.diff <= 0;
  }
}
