




































































import {Component, Vue} from 'vue-property-decorator';
import {mapGetters, mapState} from 'vuex';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import ResumoToggle from '@/components/ResumoToggle.vue';
import BackgroundImage from '@/components/BackgroundImage.vue';
import DetalhesPlano from '@/components/detalhes/DetalhesPlano.vue';
import DetalhesSidebar from '@/components/detalhes/DetalhesSidebar.vue';
import PropostaContador from '@/components/proposta/PropostaContador.vue';
import {detalhesCobertura, detalhesPlano} from '@/components/detalhes/detalhes';

@Component({
  components: {
    ResumoToggle,
    BackgroundImage,
    DetalhesSidebar,
    DetalhesPlano,
    PropostaContador,
    'pab-header': Header,
    'pab-footer': Footer,
  },
  computed: {
    ...mapState(['cotacao']),
    ...mapGetters(['veiculo_ano', 'valor_mensalidade', 'get_email']),
  },
})
export default class App extends Vue {
  get detalhesPlano() {
    return detalhesPlano;
  }

  get detalhesCobertura() {
    return detalhesCobertura;
  }

  public mounted() {
    document.title = 'Simulador Proteauto Brasil';
    // Muda titulo da página quando muda de rota
    const query = (this.$route.query as unknown) as { id: number };
    this.$store.direct.dispatch.LOAD_COTACAO(query.id);
    this.$store.commit('SET_SIDEBAR_SHOWING', query.id > 0);
    this.$store.commit('SET_SIDEBAR_ENABLED', query.id > 0);

    window.funnelytics.functions.step();
  }
}
